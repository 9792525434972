<template>
    <WalletLayout>
        <div class="row main-div gap-3">
            <div class="col-12">
                <div class="card rounded-4">
                    <div class="card-body">
                        <AvailableBalance :data="balancesData" />
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card rounded-4">
                    <div class="card-body">
                        <div class="row align-items-center flex-column flex-lg-row gy-2">
                            <div class="col">
                                <h6 class="title fw-600 fs-20 m-0 text-capitalize">{{ active_tab }}</h6>
                            </div>
                            <div class="col-lg-4 col-12">
                                <div class="input-group search-input">
                                    <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>
                                    <input type="search" v-model="search" ref="searchInput" v-on:keyup="searchData()"
                                        placeholder="Search" class="form-control ">
                                </div>
                            </div>
                            <div class="col-12 py-3">
                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item fs-14" role="presentation" v-for="(item, index) in tabItems"
                                        :key="index">
                                        <button class="nav-link nav_btns fs-14 fw-400"
                                            :class="active_tab == item.id ? 'active' : ''" @click="selectTab(item.id)"
                                            :id="`pills-${item.id}-tab`" data-bs-toggle="pill"
                                            :data-bs-target="`#pills-${item.id}`" type="button" role="tab"
                                            :aria-controls="`pills-${item.id}`" aria-selected="true">{{ item.tab }}</button>
                                    </li>
                                </ul>
                                <div class="tab-pane fade show" :class="active_tab == item.id ? 'active' : ''"
                                    :id="`pills-${item.id}`" v-for="(item, index) in tabItems" :key="index" role="tabpanel"
                                    :aria-labelledby="`pills-${item.id}-tab`">
                                </div>

                                <Holdings :data="holdings" @updateList="getSubscribed" v-if="active_tab == 'holdings'" />
                                <TransactionsList :data="transactionData" @updateResponse="walletTransactions"
                                    v-if="active_tab == 'transactions'" />
                                <!-- </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- <div class="row portfolio-upper-row g-3">
        <div class="col-lg-8 col-12">
          <WalletBalance :wallet="wallet" />
        </div>
        <div class="col-lg-4 ps-lg-0 col-12">
          <Transaction />
        </div>
        <div class="col-lg-">
          <Balances :response="response" :balances="balances" />
        </div>
      </div> -->
    </WalletLayout>
</template>
  
<script>


import WalletLayout from "@/layouts/WalletLayout.vue";
import Holdings from "@/components/Wallet/Staking/Holdings.vue";
import TransactionsList from "@/components/Wallet/Staking/TransactionsList";
import AvailableBalance from "@/components/Wallet/AvailableBalance";
import ApiClass from "@/api/api";
// import exactMath from "exact-math";
export default {
    name: "Spot",
    components: {
        WalletLayout,
        Holdings,
        TransactionsList,
        AvailableBalance,
    },
    data() {
        return {
            first_holding: true,
            first_txn: true,
            search: "",
            updateRes: "",
            balancesData: {
                heading: 'Staking & Earn',
                type: 'staking',
                subheading: 'Available funds in your account',
                buttons: [
                    { name: 'Start stake', link: '/staking', class: 'text-primary border-primary' },
                    // { name: 'History', link: '/user/user_staking_list', class: 'btn-primary text-white' }
                ],
                availableQty: 0,
                availableBal: 0,
                cards: [
                    { name: 'Total Investment', icon: 'deposit.svg', qty: 0, bal: 0 },
                    { name: 'Total Return', icon: 'deposit.svg', qty: 0, bal: 0 },
                    { name: 'Total Profit', icon: 'deposit_up.svg', qty: 0, bal: 0 },
                ],
            },
            holdings: {
                res: [],
                balances: [],
                skeletonLoad: true,
                loading: true,
                from_page: 0,
                last_page: 0,
                current_page: 0,
                tableHeading: ['Coin', 'Type', 'APR', 'ROI %', 'Amount', 'Start Date', 'Expiry Date', 'Status'],
            },
            transactionData: {
                res: [],
                balances: [],
                skeletonLoad: true,
                loading: true,
                from_page: 0,
                last_page: 0,
                current_page: 0,
                tableHeading: ['Coin', 'Type', 'Amount', 'Balance', 'Comment'],
            },
            // perPageData: 0,
            page: 1,
            per_page: 10,
            totalData: 0,
            active_tab: "holdings",
            tabItems: [
                { tab: 'My Holdings', id: 'holdings' },
                { tab: 'Transactions', id: 'transactions' },
                // { tab: 'Wallet Balance', id: 'balance'},
            ],
        }
    },
    mounted() {
        this.selectTab('holdings');
        // this.walletTransactions(1);
        // this.getstakeWalletBalance();
    },
    methods: {
        searchData() {
            var search = this.search.toUpperCase();
            if (this.active_tab == 'holdings') {
                if (search) {
                    this.holdings.res = this.updateRes.filter((e) => e.stake_currency.includes(search));
                } else {
                    this.holdings.res = this.updateRes.filter((e) => e);
                }
            }

            if (this.active_tab == 'transactions') {
                if (search) {
                    this.transactionData.res = this.updateRes.filter((e) => e.currency.includes(search) || e.transaction_type.includes(search.toLowerCase()));
                } else {
                    this.transactionData.res = this.updateRes.filter((e) => e);
                    // this.searchZeroData();
                }
            }

        },
        setAmount(amount) {
            this.trans_with_amount = amount;
        },
        selectTab(item) {
            this.active_tab = item;
            if (item == 'holdings' && this.first_holding == true) {
                this.getSubscribed(1);
            }
            if (item == 'transactions' && this.first_txn == true) {
                this.walletTransactions(1);
            }
        },
        // setTransfer(data) {
        //     this.trans_amount = data.withdrawable;
        //     this.trans_with_amount = (data.withdrawable == 0) ? "" : data.withdrawable;
        //     this.transferCurrency = data.currency;
        // },
        async getSubscribed(h_page) {
            this.first_holding = false;
            h_page == 1 ? this.holdings.skeletonLoad = true : this.holdings.loading = true;

            const response = await ApiClass.getNodeRequest("staking/myplans?per_page=" + this.per_page + "&page=" + h_page, true);
            if (response.data.status_code == 1) {
                this.holdings.skeletonLoad = false;
                this.holdings.loading = false;
                let ress = response.data;
                let newArray = [...this.holdings.res, ...ress.data.data];
                this.holdings.res = this.updateRes = this.$helpers.getUniqArrObj(newArray);
                this.holdings.from_page = ress.from;
                this.holdings.last_page = ress.last_page;
                this.holdings.current_page = ress.current_page;
            }
        },

        async walletTransactions(page) {
            this.first_txn = false;
            page == 1 ? this.transactionData.skeletonLoad = true : this.transactionData.loading = true;

            const response = await ApiClass.getNodeRequest("staking/wallet-transactions?per_page=" + this.per_page + "&page=" + page, true);
            if (response.data.status_code == 1) {
                this.transactionData.skeletonLoad = false;
                this.transactionData.loading = false;

                let ress = response.data.data;

                let newArray = [...this.transactionData.res, ...ress.data];
                this.transactionData.res = this.updateRes = this.$helpers.getUniqArrObj(newArray);

                this.transactionData.from_page = ress.from;
                this.transactionData.last_page = ress.last_page;
                this.transactionData.current_page = ress.current_page;
            }
        },

    },
    watch: {
        $route: function () {
            let slug = this.$route.params.slug;
            slug == 'history' ? this.selectTab('transactions') : this.selectTab('holdings');
        }
    },
};
</script>
<style scoped>
.wallet-box {
    background-color: var(--bg5);
}

/* .table-height{
    height: 100%;
    max-height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
  } */

.wallet-box .wallet_user {
    font-size: 26px;
}

@media only screen and (max-width: 767px) {
    .wallet-box .wallet_user {
        font-size: 24px;
    }

    .shadow-sm,
    .shadow-lg,
    .shadow {
        box-shadow: none !important;
    }
}
</style>