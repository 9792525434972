<template>
  <div class="table-container table-height table-responsive custom-scrollbar" ref="tableContainer"
    v-on:scroll="handleScroll">
    <table class="table table-borderless align-middle" ref="tablelist" id="table-list">
      <thead>
        <tr class="fs-13 fw-500 font-secondary m-0 text-nowrap">
          <th scope="col" v-for="item in data.tableHeading" :key="item">{{ item }}
          </th>
        </tr>
      </thead>
      <tbody class="fiat-chart">
        <template v-if="data.skeletonLoad">
          <tr class="align-middle" v-for="i in data.tableHeading.length" :key="i">
            <td class="text-uppercase" v-for="j in data.tableHeading.length" :key="j">
              <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                wave-color="#fff" :rounded="true" />
            </td>
          </tr>
        </template>
        <template v-if="data.res.length > 0">
          <tr class="border-0" v-for="(item, i) in data.res" :key="i">
            <td>
              <div class="d-flex gap-2 align-items-center">
                <img :src="item.currency_image" width="23">
                <h6 class="fs-15 m-0 fw-600">{{ item.currency }}</h6>
                <!-- <span class="bg-black ms-2 p-1 rounded-3 fw-semibold small text-center text-warning">BTC</span> -->
              </div>
            </td>
            <td>
              <h6 class="subtitle text-uppercase">{{ item.transaction_type }}</h6>
            </td>
            <td>
              <h6 class="subtitle" :class="item.debit > 0 ? 'text-danger' : 'text-success'">{{
                item.debit > 0 ?
                  $helpers.NumberFixed(item.debit, 8) : $helpers.NumberFixed(item.credit, 8)
              }}</h6>
            </td>
            <td>
              <h6 class="subtitle">{{ $helpers.NumberFixed(item.balance, 8) }}</h6>
            </td>
            <td>
              <h6 class="subtitle">{{ item.comment }}</h6>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="col-12 text-center" v-if="data.loading && !data.skeletonLoad">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="data.res.length < 1 && !data.skeletonLoad"
      class="d-flex border-0 flex-column text-center justify-content-center py-4 gap-2">
      <div>
        <img width="70" :src="require(`../../../assets/images/icons/no-record.svg`)" />
      </div>
      <span class="fw-500 fs-14">No search found</span>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  name: "TransactionsList",
  components: {
    VueSkeletonLoader
  },
  props: {
    updateResponse: Function,
    data: Object,
  },
  data() {
    return {
      currPage: this.data.current_page,
    }
  },
  async mounted() {
    // alert(JSON.stringify(this.updateResponse))
    // if (this.data.balances) {
    //   this.loading = false;
    // }
  },
  computed: {
    computeBalance() {
      let qty = 0;
      for (let item of this.data.balances) {
        qty = parseFloat(item.quantity) + parseFloat(item.fund_quantity) + parseFloat(item.stake_quantity);
      }
      return qty.toFixed(4);
    },

    computeUsd() {
      let usd = 0;
      for (let item of this.data.balances) {
        usd = parseFloat(item.c_bal) + parseFloat(item.stake_bal) + parseFloat(item.fund_bal);
      }
      return usd.toFixed(4);
    }
  },
  methods: {
    handleScroll() {
      let el = this.$refs.tableContainer;
      let table = this.$refs.tablelist;

      if (this.currPage <= this.data.current_page && this.data.current_page < this.data.last_page) {
        if (el.scrollTop + el.clientHeight >= table.clientHeight) {
          this.currPage = this.data.current_page + 1;
          this.$emit('updateResponse', this.currPage);
        }
      }
    },
  }
};
</script>
<style scoped>
.form-check-input:checked {
  background-color: #cc9a02;
  border-color: #cc9a02;
}

#fiat-table .dropdown-menu {
  min-width: max-content;
}

.table-height {
  height: auto;
  min-height: 15rem;
  max-height: 25rem;
  overflow-y: scroll;
}

/* 
.table thead th {
  color: var(--text);
} */
</style>
