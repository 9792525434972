<template>
  <div class="table-container table-height table-responsive custom-scrollbar" ref="tableContainer"
    v-on:scroll="handleScroll">
    <table class="table table-borderless align-middle" id="table-list" ref="tablelist">
      <thead class="">
        <tr class="fs-13 fw-500 font-secondary m-0 text-nowrap">
          <th scope="col" v-for="item in data.tableHeading" :key="item">{{ item }}
          </th>
        </tr>
      </thead>
      <tbody id="coins" v-if="data.skeletonLoad">
        <tr class="align-middle" v-for="i in 5" :key="i">
          <td class="text-uppercase" v-for="j in 7" :key="j">
            <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd" wave-color="#fff"
              :rounded="true" />
          </td>
        </tr>
      </tbody>
      <template v-if="data.res.length > 0">
        <tbody class="fiat-chart" v-for="(item, index) in data.res" :key="index">
          <tr class="border-0" v-for="(data, i) in item.pair_data" :key="i">
            <td>
              <div class="d-flex gap-2 align-items-center">
                <img :src="item.stake_currency_image" width="23">
                <h6 class="fs-15 m-0 fw-600">{{ data.stake_currency }}</h6>
                <!-- <span class="bg-black ms-2 p-1 rounded-3 fw-semibold small text-center text-warning">BTC</span> -->
              </div>
            </td>
            <td>
              <h6 class="subtitle text-uppercase">{{ data.plan_type }}</h6>
            </td>
            <td>
              <h6 class="subtitle text-success fw-semibold">{{ data.staking_plan.roi_percentage }}%</h6>
            </td>
            <td>
              <h6 class="subtitle">{{ $helpers.NumberFixed(data.roi_income,8) }}</h6>
            </td>
            <td>
              <h6 class="subtitle">{{ $helpers.NumberFixed(data.amount,8) }}</h6>
            </td>
            <td>
              <h6 class="subtitle">{{ showdate(data.next_roi_date) }}</h6>
            </td>
            <td>
              <h6 class="subtitle">{{ showdate(data.expiry_date) }}</h6>
            </td>
            <td>
              <div v-if="data.plan_type == 'flexible' && data.is_active == true">
                <button @click="confirmFunc(data.id)" type="button" class="btn text-primary border-primary btn-sm">
                  Unsubscribe </button>
              </div>
              <div v-if="data.plan_type == 'fixed' && data.is_active == true">
                <button type="button" class="btn btn-sm status_btn"> Locked
                </button>
              </div>
              <div v-if="data.plan_type == 'flexible' && data.is_active == false">
                <button type="button" class="btn btn-sm status_btn"> Inactive
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </table>
    <div class="col-12 text-center" v-if="data.loading && !data.skeletonLoad">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="data.res.length < 1 && !data.skeletonLoad"
      class="d-flex border-0 flex-column text-center justify-content-center py-4 gap-2">
      <div>
        <img width="70" :src="require(`../../../assets/images/icons/no-record.svg`)" />
      </div>
      <span class="fw-500 fs-14">No search found</span>
    </div>
  </div>
</template>

<script>
import ApiClass from "@/api/api";
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  name: "Holdings",
  components: {
    VueSkeletonLoader
  },
  props: {
    updateList: Function,
    data: Object,
  },
  data() {
    return {
      currPage: this.data.current_page,
    }
  },
  async mounted() {
    // if (this.data.balances) {
    //   this.loading = false;
    // }
  },
  computed: {
    computeBalance() {
      let qty = 0;
      for (let item of this.data.balances) {
        qty = parseFloat(item.quantity) + parseFloat(item.fund_quantity) + parseFloat(item.stake_quantity);
      }
      return qty.toFixed(4);
    },

    computeUsd() {
      let usd = 0;
      for (let item of this.data.balances) {
        usd = parseFloat(item.c_bal) + parseFloat(item.stake_bal) + parseFloat(item.fund_bal);
      }
      return usd.toFixed(4);
    }
  },
  methods: {
    handleScroll() {
      let el = this.$refs.tableContainer;
      let table = this.$refs.tablelist;

      if (this.currPage <= this.data.current_page && this.data.current_page < this.data.last_page) {
        if (el.scrollTop + el.clientHeight >= table.clientHeight) {
          this.currPage = this.data.current_page + 1;
          this.$emit('updateList', this.currPage);
        }
      }
    },
    async confirmFunc(id) {
      this.$swal.fire({
        title: 'Are you sure you want to Unsubscribe?',
        showCloseButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#db9d2b",
        showCancelButton: true,
        cancelButtonText: 'No',
        cancelButtonColor: "#db9d2b"
      }).then((result) => {
        if (result.isConfirmed) {
          this.unsubscribe(id);
        }
      })
    },
    async unsubscribe(id) {
      let res = await ApiClass.postNodeRequest("staking/unsubscribe", true, { user_stake_id: id });
      if (res.data.status_code == 1) {
        this.$swal({
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          toast: true,
          timer: 3000,
        });
        // this.getSubscribed(1);
      } else {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          toast: true,
          timer: 3000,
        });
      }
    },
    maturity_day(expiry_date) {
      let Exp_date = new Date(expiry_date).getTime();
      let today_date = new Date().getTime();
      var Difference_In_Time = Exp_date - today_date;
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Math.ceil(Difference_In_Days);
    },
    showdate(date) {
      date = date.split("T")[0].split("-")
      return date[2] + "/" + date[1] + "/" + date[0];
    },
  }
};
</script>
<style scoped>
.form-check-input:checked {
  background-color: #cc9a02;
  border-color: #cc9a02;
}

#fiat-table .dropdown-menu {
  min-width: max-content;
}

.table-height {
  height: auto;
  min-height: 15rem;
  max-height: 25rem;
  overflow-y: scroll;
}

.status_btn {
  background-color: var(--bg5);
  cursor: auto;
  color: var(--onsurface) !important;
}

.spinner-border-sm {
  width: 2rem;
  height: 2rem;
  border-width: 0.2em;
}

/* 
.table thead th {
  color: var(--text);
} */
</style>
